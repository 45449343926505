<script setup lang="ts">
import { UI05Note, UI05StatusBlock } from 'ui-05'
import type { StatusType } from '~/type/StatusType'
import type ErrorType from '~~/type/Error'

defineProps<{
  verifyStatus?: StatusType
  confirmStatus?: StatusType
  verifyErrorValue?: ErrorType
  confirmErrorValue?: ErrorType
}>()

const emit = defineEmits<{
  (e: 'submit'): void
  (e: 'confirm', value: string): void
}>()

const isShow = ref(false)

function send() {
  isShow.value = true
  emit('submit')
}
</script>

<template>
  <UI05Note size="m">
    <p class="h5 mb-8">
      Мы ввели обязательное подтверждение телефона
    </p>

    <div class="p mb-10">
      Чтобы вы имели доступ ко всем возможностям личного кабинета и получали актуальную информацию о своих заказах.
    </div>

    <span
      v-if="!isShow"
      class="link link_red"
      @click="send"
    >Подтвердить телефон</span>

    <UI05StatusBlock
      v-if="verifyStatus === 'loading'"
      type="loading"
      size="s"
    />
    <UI05StatusBlock
      v-else-if="verifyStatus === 'error'"
      type="error"
      size="s"
      :title="verifyErrorValue?.description"
    />
    <div v-else-if="isShow">
      <UserConfirmCode
        :status="confirmStatus"
        :error-value="confirmErrorValue"
        @submit="$emit('confirm', $event)"
      />
    </div>
  </UI05Note>
</template>
